.App {
  text-align: center;
  font-size: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-image{
  width: 110px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiTableCell-root:not(.MuiTableCell-paddingNone){
  padding:9px !important;
}
.MuiTablePagination-selectRoot,.MTablePaginationInner-root-36{
background-color:rgb(211, 211, 211,0.9);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.flexible-modal {
  position: absolute;
  z-index: 99999 !important;
  border:none !important;
  box-shadow: 0px 0px 33px -13px rgba(240,240,240,1);
  background: white;
  border-radius: 12px;
  overflow: hidden !important;


}
.flexible-modal-mask {
  position: absolute !important;
  background: rgba(0, 0, 0, 0.0) !important;
  top:50%;
  /* overflow: hidden !important; */
  left:0;
  right:0;
  bottom:0;
  z-index: 999 !important;

}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
  border-radius: 12px;

}
.flexible-modal-drag-area{
  background: rgba(0,0,0,0) !important;
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor: pointer !important;
  padding-bottom: 20px;
  border-radius: 12px;
}