.custom-dialer{
    background-color: #FBFBFB;
    padding-bottom: 20px;
    border-radius: 12px;
    height:100%
}

.min-dialer{
    height: 140px !important;
    right:20px !important;
    top: 50% !important;
    left: auto !important;
}

.max-dialer{
    height: 490px !important;
    right:20px !important;
    top: 25% !important;
    left: auto !important;
}

.call-heading-box{
    background-color: #256CD6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
}
.status-heading-box{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    /* cursor: pointer; */
}
.status-box{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status-box > h4{
    font-size: 18px;
    color: #949494;
    padding-left: 5px;
}

.call-heading-box>img{
    width: 35px;
}

.call-heading{
    margin-top: 0px;
    color: #fff ;
    padding: 14px 0px;
}

.dialer-number{
    color: #232323 !important;
}

.dialer-modal{
    position: absolute !important;
    background: rgba(0, 0, 0, 0.0) !important;
    top:0%;
    /* overflow: hidden !important; */
    left:0;
    right:0;
    bottom:0;
    pointer-events: none !important;
    z-index: 99 !important;
}

.phone-box{
    background-color: #fff !important;
    width: 257px;
    border-radius: 12px;
    position: relative;
    pointer-events: auto !important;

    /* z-index: 999 !important; */
}

.keypad-input{
    font-size: 22px;
    text-align: center;
    border: none !important;
    color: #232323 !important;
    background-color: #FBFBFB;
    padding: 12px 0px;
}

.keypad-input:focus{
    border: none !important;
    outline: none !important;
}